<template>
  <div>
    <div class="drawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
      <div
        class="drawer__overlay"
        :style="{ transitionDuration: `${speed}ms` }"
      ></div>
      <div
        class="drawer__content"
        v-click-away="closeDrawer"
        :style="{
          transitionDuration: `${speed}ms`,
          backgroundColor: backgroundColor,
        }"
      >
        <slot></slot>

        <div class="drawer-slider" v-if="eventData">
          <div class="d-flex justify-content-between">
            <div>
              <h3>{{ eventData.title }}</h3>
              <p>
                {{ eventData.description }}
              </p>
            </div>
          </div>
          <div class="event-info">
            <div>
              <h4>{{ $t("Schedules") }}</h4>
              <div class="d-flex flex-column">
                <label class="text-secondary">Due Date</label>
                <span>{{ eventData.dueDate }}</span>
                <label class="mt-3 text-secondary">Type</label>
                <span>{{ eventData.type }}</span>
                <label class="mt-3 text-secondary">Audience</label>
                <span v-if="eventData.audience === 'individual'"
                  ><i
                    v-c-tooltip="{
                      content: eventData.audience,
                      placement: 'top',
                    }"
                    class="fa fa-user"
                  ></i
                ></span>
                <span v-else
                  ><i
                    v-c-tooltip="{
                      content: eventData.audience,
                      placement: 'top',
                    }"
                    class="fa fa-users"
                  ></i
                ></span>
                <label class="mt-3 text-secondary">Goal</label>
                <span>{{ eventData.goal }}</span>
              </div>
            </div>
            <div class="">
              <h4 class="">{{ $t("Badge") }}</h4>
              <div v-html="eventData.badge"></div>
            </div>
          </div>
        </div>
        <!--Participants-->
        <!-- <div>
          <div>
            <CarouselChallenge :eventData="eventData" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";
import CarouselChallenge from "./CarouselChallenge.vue";
import { AgendaService } from "../../../services/agenda";
import { showMessage } from "../../../utils/helpers";
export default {
  name: "Drawer",
  components: {
    CarouselChallenge,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "40%",
    },
    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fafafa",
    },
    eventData: {
      type: Object,
      default: null,
    },
    trainingData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false,
      isTransitioning: false,
    };
  },
  watch: {
    isOpen(val) {
      this.isTransitioning = true;
      if (val) {
        this.toggleBackgroundScrolling(true);
        this.isVisible = true;
      } else {
        this.toggleBackgroundScrolling(false);
        setTimeout(() => (this.isVisible = false), this.speed);
      }
      setTimeout(() => (this.isTransitioning = false), this.speed);
    },
  },
  methods: {
    joinMeeting() {
      if (this.eventData && this.eventData.link) {
        window.open(this.eventData.link, "_blank");
      } else {
        showMessage("error", $t("Meeting link not available"));
      }
    },
    async downloadFile(eventData, attachment) {
      console.log(eventData);
      try {
        const { organization, project } = eventData;
        const event_title = eventData.title;
        const { filename } = attachment;
        const downloadingFile = {
          organization,
          project,
          event_title,
          filename,
        };
        const response = await AgendaService.download_attachment(
          downloadingFile
        );
        console.log("Success");
      } catch (error) {
        console.error("error downloading file", error);
      }
    },
    toggleBackgroundScrolling(enable) {
      const body = document.querySelector("body");
      body.style.overflow = enable ? "hidden" : null;
    },
    closeDrawer() {
      if (!this.isTransitioning) {
        this.$emit("close");
      }
    },
    formatDate(dateTime) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const dateToReturn =
        this.$i18n.locale == "fr"
          ? new Date(dateTime).toLocaleDateString("fr-FR", options)
          : new Date(dateTime).toLocaleDateString("en-US", options);
      return dateToReturn;
    },
    formatTime(dateTime) {
      return new Date(dateTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    this.isVisible = this.isOpen;
  },
};
</script>

<style lang="scss" scoped>
.fa-user,
.fa-users {
  font-size: 30px;
  color: #0071ce;
}
.event-info {
  display: flex;
  justify-content: space-between;
}
.attachment-filename {
  display: flex;
  align-items: center;
  padding: 8px;
}
.filename {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}
.attachment-filename:first-child {
  border-top: 1px solid #ccc;
}
.attachment-filename + .attachment-filename {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.drawer-slider {
  // background-color: rgba(218, 218, 218, 0.226);
  width: 100%;
  margin: 0 auto;
  // margin-top: 20px;
  padding: 15px;
  height: 50%;
  // padding-left: 7px;
}
.join-btn {
  display: flex;
  align-items: center;
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
  margin-top: 9px;
  // margin-right: 17px;
  padding: 0 4px;
  // width: max-content;
}
.drawer__content {
  max-width: 40%;
}
@media (max-width: 613px) {
  .event-info {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 593px) {
  .drawer__content {
    max-width: 60%;
  }
}
@media (max-width: 350px) {
  .drawer__content {
    max-width: 70%;
  }
}
.drawer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    .drawer__overlay {
      opacity: 0.5;
    }

    .drawer__content {
      transform: translateX(0);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    opacity: 0;
    transition-property: opacity;
    background-color: #fff;
    user-select: none;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    overflow: auto;
    transition-property: transform;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    box-shadow: 0 2px 6px #777;
  }
}
</style>
