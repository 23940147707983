<template>
  <div class="challengesInfoPage">
    <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
      {{ $t("Challenges") }}
    </h1>
    <div class="span-switch">
      <span
        @click="switchToTab('all')"
        :class="{ active: activeTab === 'all' }"
        class="filter"
        >{{ $t("All") }}</span
      >
      <span
        @click="switchToTab('my_challenges')"
        :class="{ active: activeTab === 'my_challenges' }"
        class="filter"
        >{{ $t("My challenges") }}</span
      >
      <span
        @click="switchToTab('open_challenges')"
        :class="{ active: activeTab === 'open_challenges' }"
        class="filter"
        >{{ $t("Open challenges") }}</span
      >
    </div>
    <div class="container_challenges">
      <div class="mt-3">
        <div class="title_filters">
          <h4 class="mb-0">Due in the next 7 days</h4>
          <i
            class="pointer"
            @click="toggleSection('dueInNext7Days')"
            :class="
              showDueInNext7Days ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            "
          ></i>
        </div>
        <div v-if="showDueInNext7Days">
          <div v-if="dueInNext7DaysChallenges.length === 0">
            <el-empty
              :description="$t('No challenges available')"
              style="margin: auto; padding: 0"
            />
          </div>
          <div class="cards_container" v-else>
            <div class="card_challenges mt-3">
              <div
                v-for="challenge in dueInNext7DaysChallenges"
                :key="challenge.id"
                @click="openDetails(challenge)"
              >
                <div class="cards_challenges">
                  <!-- <div
                    v-if="challenge.is_active === false"
                    class="desactivated_challenge"
                    v-c-tooltip="{
                      content: 'Inactive',
                      placement: 'top',
                    }"
                  >
                    <i class="fa fa-ban"></i>
                  </div> -->
                  <div>
                    <div
                      v-html="getBadgeSVG(challenge.badge)"
                      class="challengeInfoBadge"
                      :class="{
                        bronze_badge: challenge.color === 'Bronze',
                        silver_badge: challenge.color === 'Silver',
                        gold_badge: challenge.color === 'Gold',
                        teal_badge: challenge.color === 'Teal',
                        light_green_badge: challenge.color === 'Light Green',
                      }"
                    ></div>
                  </div>
                  <h4 class="title_challenge">{{ challenge.title }}</h4>
                  <p class="mb-0">
                    {{ truncateString(challenge.description) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="title_filters">
          <h4 class="mb-0">Coming</h4>
          <i
            class="pointer"
            @click="toggleSection('comingChallenges')"
            :class="
              showComingChallenges ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            "
          ></i>
        </div>
        <div v-if="showComingChallenges">
          <div v-if="comingChallenges.length === 0">
            <el-empty
              :description="$t('No challenges available')"
              style="margin: auto; padding: 0"
            />
          </div>
          <div class="cards_container" v-else>
            <div class="card_challenges mt-3">
              <div
                v-for="challenge in comingChallenges"
                :key="challenge.id"
                @click="openDetails(challenge)"
              >
                <div class="cards_challenges">
                  <!-- <div
                    v-if="challenge.is_active === false"
                    class="desactivated_challenge"
                    v-c-tooltip="{
                      content: 'Inactive',
                      placement: 'top',
                    }"
                  >
                    <i class="fa fa-ban"></i>
                  </div> -->
                  <div>
                    <div
                      v-html="getBadgeSVG(challenge.badge)"
                      class="challengeInfoBadge"
                      :class="{
                        bronze_badge: challenge.color === 'Bronze',
                        silver_badge: challenge.color === 'Silver',
                        gold_badge: challenge.color === 'Gold',
                        teal_badge: challenge.color === 'Teal',
                        light_green_badge: challenge.color === 'Light Green',
                      }"
                    ></div>
                  </div>
                  <h4 class="title_challenge">{{ challenge.title }}</h4>
                  <p class="mb-0">
                    {{ truncateString(challenge.description) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="title_filters">
          <h4 class="mb-0">Past</h4>
          <i
            @click="toggleSection('pastChallenges')"
            class="pointer"
            :class="
              showPastChallenges ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            "
          ></i>
        </div>
        <div v-if="showPastChallenges">
          <div v-if="pastChallenges.length === 0">
            <el-empty
              :description="$t('No challenges available')"
              style="margin: auto; padding: 0"
            />
          </div>
          <div class="cards_container" v-else>
            <div class="card_challenges mt-3">
              <div
                v-for="challenge in pastChallenges"
                :key="challenge.id"
                @click="openDetails(challenge)"
              >
                <div class="cards_challenges">
                  <div>
                    <div
                      v-html="getBadgeSVG(challenge.badge)"
                      class="challengeInfoBadge"
                      :class="{
                        bronze_badge: challenge.color === 'Bronze',
                        silver_badge: challenge.color === 'Silver',
                        gold_badge: challenge.color === 'Gold',
                        teal_badge: challenge.color === 'Teal',
                        light_green_badge: challenge.color === 'Light Green',
                      }"
                    ></div>
                  </div>
                  <h4 class="title_challenge">{{ challenge.title }}</h4>
                  <p class="mb-0">
                    {{ truncateString(challenge.description) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChallengeDetails
      :showInfo="showInfo"
      @close="closeDetails"
      :challenge="challengeData"
    />
  </div>
</template>
<script>
import { images } from "@/assets/Images/images";
import DrawerChallenge from "./component/DrawerChallenge.vue";
import ChallengeDetails from "./component/ChallengeDetails.vue";
import { useGlobalStore } from "../../stores/store";
import { mapWritableState } from "pinia";
import { ChallengeService } from "@/services/challenges";
import { mapState } from "vuex";
import { ElLoading } from "element-plus";

const badges = images.challenges;
export default {
  name: "Challenges Info",
  components: {
    DrawerChallenge,
    ChallengeDetails,
  },
  computed: {
    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  data() {
    return {
      globalStore: useGlobalStore(),
      isDrawerOpen: false,
      showInfo: false,
      challengeData: null,
      challenges: [],
      activeChallenges: [],
      activeTab: "all",
      dueInNext7DaysChallenges: [],
      comingChallenges: [],
      pastChallenges: [],
      showDueInNext7Days: true,
      showComingChallenges: true,
      showPastChallenges: true,
    };
  },
  async created() {
    await this.getChallengesData();
  },

  methods: {
    toggleSection(section) {
      switch (section) {
        case "dueInNext7Days":
          this.showDueInNext7Days = !this.showDueInNext7Days;
          break;
        case "comingChallenges":
          this.showComingChallenges = !this.showComingChallenges;
          break;
        case "pastChallenges":
          this.showPastChallenges = !this.showPastChallenges;
          break;
        default:
          break;
      }
    },
    switchToTab(tab) {
      this.activeTab = tab;
      switch (tab) {
        case "my_challenges":
          // Filter challenges related to the user
          this.dueInNext7DaysChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Due in the next 7 days" &&
              this.isChallengeInUserData(challenge._id)
          );
          this.comingChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Coming" &&
              this.isChallengeInUserData(challenge._id)
          );
          this.pastChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Past" &&
              this.isChallengeInUserData(challenge._id)
          );

          break;
        case "open_challenges":
          // Filter challenges with non-obtained badges
          this.dueInNext7DaysChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Due in the next 7 days" &&
              !this.isChallengeInUserData(challenge._id)
          );
          this.comingChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Coming" &&
              !this.isChallengeInUserData(challenge._id)
          );
          this.pastChallenges = this.challenges.filter(
            (challenge) =>
              challenge.status === "Past" &&
              !this.isChallengeInUserData(challenge._id)
          );
          break;
        default:
          // Display all challenges
          this.dueInNext7DaysChallenges = this.challenges.filter(
            (challenge) => challenge.status === "Due in the next 7 days"
          );
          this.comingChallenges = this.challenges.filter(
            (challenge) => challenge.status === "Coming"
          );
          this.pastChallenges = this.challenges.filter(
            (challenge) => challenge.status === "Past"
          );
          break;
      }
    },
    isChallengeInUserData(challengeId) {
      return this.userData.challenges.some(
        (challenge) => challenge._id === challengeId
      );
    },
    getBadgeSVG(badgeId) {
      const badge = badges[badgeId];
      if (badge) {
        return badge;
      } else {
        console.error("Badge not found");
        return null;
      }
    },
    async getChallengesData() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      try {
        const { data, res } = await ChallengeService.getChallenges();
        this.challenges = data.data;
        // Assuming `current_job_description` is defined somewhere accessible in your component
        const jobDescriptionKey = Object.keys(
          this.userData.current_job_description
        ).find((key) => key !== "level_id" && key !== "level_name");

        this.challenges = this.challenges.filter((challenge) => {
          const usersAccessArray = Array.from(challenge.users_access);

          return usersAccessArray.some((access) => {
            // Check if `access` is an object and contains the required properties
            if (
              typeof access === "object" &&
              access !== null &&
              "email" in access &&
              "job_description" in access
            ) {
              const emailMatch =
                access.email.trim().toLowerCase() ===
                this.userData.email.trim().toLowerCase();
              const jobDescriptionMatch =
                access.job_description.trim().toLowerCase() ===
                jobDescriptionKey.trim().toLowerCase();

              return emailMatch && jobDescriptionMatch;
            } else {
              return (
                challenge.users_access &&
                challenge.users_access.includes(this.userData.id)
              );
              return false;
            }
          });
        });

        const currentDate = new Date();
        const next7Days = new Date(currentDate);
        next7Days.setDate(next7Days.getDate() + 7);

        this.challenges.forEach((challenge) => {
          const dueDate = new Date(challenge.due_date);
          if (dueDate.getTime() <= currentDate.getTime()) {
            challenge.status = "Past";
          } else if (
            dueDate.getTime() > currentDate.getTime() &&
            dueDate.getTime() <= next7Days.getTime()
          ) {
            challenge.status = "Due in the next 7 days";
          } else {
            challenge.status = "Coming";
          }
        });

        this.dueInNext7DaysChallenges = this.challenges.filter(
          (challenge) =>
            challenge.status === "Due in the next 7 days" &&
            challenge.is_active === true
        );
        this.comingChallenges = this.challenges.filter(
          (challenge) =>
            challenge.status === "Coming" && challenge.is_active === true
        );
        this.pastChallenges = this.challenges.filter(
          (challenge) =>
            challenge.status === "Past" && challenge.is_active === true
        );

        if (this.globalStore.challengeToOpen) {
          this.openDetails(this.globalStore.challengeToOpen);
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading.close();
      }
    },

    truncateString(str) {
      if (str.length > 100) {
        return str.substring(0, 100) + "...";
      } else {
        return str;
      }
    },
    openDetails(challenge) {
      this.showInfo = true;
      this.challengeData = challenge;
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    closeDetails() {
      this.showInfo = false;
    },
  },
};
</script>
<style scoped>
::v-deep .challengeInfoBadge.bronze_badge circle {
  fill: #e2a963;
}
::v-deep .challengeInfoBadge.bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .challengeInfoBadge.silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .challengeInfoBadge.silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .challengeInfoBadge.teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .challengeInfoBadge.teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .challengeInfoBadge.light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .challengeInfoBadge.light_green_badge path:first-child {
  fill: #7feb45;
}
.card_challenges {
  width: auto;
  overflow: auto;
  display: flex;
  gap: 15px;
}
.desactivated_challenge {
  position: absolute;
  right: 20px;
  top: 22px;
}
.container_challenges {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
}
.title_filters {
  display: flex;
  gap: 8px;
  align-items: center;
}
.span-switch {
  display: flex;
  height: fit-content;
  gap: 15px;
}
.filter {
  cursor: pointer;
  font-size: 16px;
  padding: 5px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.filter.active {
  width: max-content;
  color: #0071ce;
  border-bottom: 2px solid #0071ce;
  font-weight: 700;
}
.title_challenge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.grey-badge {
  filter: grayscale(100%);
}
.cards_container {
  width: 100%;
  overflow: hidden;
}
.cards_challenges {
  position: relative;
  cursor: pointer;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  height: 250px;
  width: 300px;
  border: 1px solid #d4d0d0;
  background-color: white;
}
.cards_challenges:hover {
  transform: scale(0.98); /* Increase size on hover */
  transition: transform 0.3s ease;
}
@media (max-width: 749px) {
  .cards_container {
    justify-content: center;
  }
  h1 {
    margin: 0;
    padding: 0 !important;
  }
}
@media (max-width: 970px) {
  .challengesInfoPage {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .challengesInfoPage {
    margin-top: 0;
  }
}
</style>
