<template>
  <CModal
    :show="showInfo"
    @hide="closeDetails"
    :centered="true"
    :draggable="false"
  >
    <template v-slot:header>
      <h3 style="margin-left: 1rem">{{ challenge ? challenge.title : "" }}</h3>
    </template>
    <p style="margin-left: 1rem">
      {{ challenge ? challenge.description : "" }}
    </p>
    <div class="separate"></div>
    <div class="content-container">
      <div class="content-style">
        <label class="text-secondary">{{ $t("Due date") }}</label>
        <span>{{ challenge ? challenge.due_date : "" }}</span>
      </div>
      <div class="content-style">
        <label class="text-secondary">{{ $t("Type") }}</label>
        <span>{{ challenge ? challenge.challenge_type : "" }}</span>
      </div>
      <div class="content-style">
        <label class="text-secondary">{{ $t("Audience") }}</label>
        <span v-if="challenge && challenge.individual_team === 'individual'"
          ><i
            v-c-tooltip="{
              content: challenge.audience,
              placement: 'top',
            }"
            class="fa fa-user mr-2"
            style="color: #0071ce"
          ></i
          >{{ challenge.individual_team }}</span
        >
        <span v-else
          ><i
            v-c-tooltip="{
              content: challenge ? challenge.individual_team : '',
              placement: 'top',
            }"
            class="fa fa-users mr-2"
            style="color: #0071ce"
          ></i
          >{{ challenge ? challenge.individual_team : "" }}</span
        >
      </div>
      <div
        v-if="challenge && challenge.challenge_type != 'custom'"
        class="content-style"
      >
        <label class="text-secondary">{{ $t("Goal") }}</label>
        <span>{{ challenge ? challenge.goal : "" }}</span>
      </div>
      <div
        v-if="challenge && challenge.challenge_type != 'custom'"
        class="content-style"
      >
        {{}}
        <label class="text-secondary">{{ $t("Your progress") }}</label>
        <el-progress :percentage="calculateProgress()" />
      </div>
      <div class="content-style">
        <label class="text-secondary">{{ $t("Badge") }}</label>
        <div
          v-if="challenge"
          v-html="getBadgeSVG(challenge.badge)"
          :class="{
            bronze_badge: challenge.color === 'Bronze',
            silver_badge: challenge.color === 'Silver',
            gold_badge: challenge.color === 'Gold',
            teal_badge: challenge.color === 'Teal',
            light_green_badge: challenge.color === 'Light Green',
          }"
        ></div>
      </div>
      <div class="d-flex flex-column">
        <label class="text-secondary">{{ $t("Challenge status") }}</label>
        <span>{{
          challenge ? (challenge.is_active ? "Active" : "Inactive") : ""
        }}</span>
      </div>
    </div>
    <template v-slot:footer>
      <CButton @click="closeDetails" class="link">{{ $t("Close") }}</CButton>
    </template>
  </CModal>
</template>

<script>
const format = (percentage) => (percentage === 100 ? "Full" : `${percentage}%`);
import { images } from "@/assets/Images/images";
import { mapState } from "vuex";
import { useUserStore } from "@/stores/UserStore";
const badges = images.challenges;
export default {
  props: {
    showInfo: Boolean,
    challenge: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  data() {
    return {
      userStore: useUserStore(),
      progress: 0,
    };
  },
  watch: {
    showInfo() {
      this.getProgressUser();
    },
  },
  created() {
    this.getProgressUser();
  },
  methods: {
    getProgressUser() {
      if (!this.challenge) return;
      if (this.challenge.individual_team === "individual") {
        this.progress = this.userData.total_points;
        const divisor = this.progress.split(" / ")[0];
        const divisible = this.progress.split(" / ")[1];
        this.progress = (divisor / divisible) * 100;
      } else if (this.challenge.individual_team === "team") {
        this.calculateTeamProgress();
      }
    },
    calculateTeamProgress() {
      if (
        this.challenge.users_access &&
        Array.isArray(this.challenge.users_access)
      ) {
        let achievedCount = 0;
        let userCount = this.challenge.users_access.length;

        this.challenge.users_access.forEach((user) => {
          const achieved = user.points?.achieved || 0;
          if (achieved >= this.challenge.goal) {
            achievedCount++;
          }
        });

        // Calculate average progress
        this.progress = (achievedCount / userCount) * 100;
      } else {
        // If no users_access array exists, progress is 0
        this.progress = 0;
      }
    },
    calculateProgress() {
      // This function will return the correct progress based on the type
      if (this.challenge.individual_team === "individual") {
        return Math.min(
          100,
          (
            (this.userStore.allRolePoints.achieved / this.challenge.goal) *
            100
          ).toFixed(2)
        );
      } else if (this.challenge.individual_team === "team") {
        return this.progress || 0; // Progress calculated by calculateTeamProgress
      }
      return 0;
    },
    closeDetails() {
      this.$emit("close");
    },
    getBadgeSVG(badgeId) {
      const badge = badges[badgeId];
      if (badge) {
        return badge;
      } else {
        console.error("Badge not found");
        return null;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .bronze_badge circle {
  fill: #e2a963;
}
::v-deep .bronze_badge path:first-child {
  fill: #de9336;
}

::v-deep .silver_badge circle {
  fill: #d3d3d3;
}
::v-deep .silver_badge path:first-child {
  fill: #b2aeae;
}
::v-deep .teal_badge circle {
  fill: #a2c1bf;
}
::v-deep .teal_badge path:first-child {
  fill: #53908b;
}
::v-deep .light_green_badge circle {
  fill: #dfffe0;
}
::v-deep .light_green_badge path:first-child {
  fill: #7feb45;
}
.separate {
  border-top: 1px solid #e9e8e8;
}
.content-style {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.content-container {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
</style>
<style>
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}
</style>
